import { Container } from '@chakra-ui/react';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import BusinessCard from '../components/BusinessCard/BusinessCard';
import ContactForm from '../components/ContactForm/ContactForm';
import GradientText from '../components/GradientText/GradientText';
import { Page } from '../components/Page';

export default function Contact() {
  const intl = useIntl();

  return (
    <Page>
      <Container pt={[2, 8]} pb={[16, 24]} maxWidth={['90%', '45%']}>
        <GradientText pb={4}>{intl.formatMessage({ id: 'pages.contact.title' })}</GradientText>
        <ContactForm />
        <BusinessCard />
      </Container>
    </Page>
  );
}
