import { EmailIcon, InfoIcon, PhoneIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useIntl } from 'gatsby-plugin-intl';
import { isUndefined } from 'lodash';
import React, { useCallback } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { OUR_COMPANY_NAME, OUR_CONTACT_PHONE, OUR_RESPONSE_TIME_IN_HOURS } from '../../constants';
import { COLOR_DARK_ACCENT, PLACEHOLDER_TEXT_DARK, PLACEHOLDER_TEXT_LIGHT } from '../../styles/colors';

enum ContactFormContentPropNamesEnum {
  Name = 'name',
  Email = 'email',
  Topic = 'topic',
  Phone = 'phone',
  Language = 'language',
}

interface ContactFormContent {
  name: string;
  email: string;
  topic: string;
  phone: string;
  language: string;
}

interface EmailSignature {
  companyName: string;
  addressStreet: string;
  addressPostCodeAndCity: string;
  companyTaxId: string;
  ourEmail: string;
  ourPhone: string;
}

export interface ThankYouEmailContent extends FieldValues, ContactFormContent, EmailSignature {
  subject: string;
  shortText: string;
  headerGreeting: string;
  welcomeMessage: string;
  ourResponseMessage: string;
  contactUsMessage: string;
  seeYouMessage: string;
  byeBye: string;
}

const ContactForm = () => {
  const intlPath = 'pages.contact.form';
  const intl = useIntl();
  const toast = useToast();
  const placeholderColor = useColorModeValue(PLACEHOLDER_TEXT_DARK, PLACEHOLDER_TEXT_LIGHT);
  const borderColor = useColorModeValue(COLOR_DARK_ACCENT, PLACEHOLDER_TEXT_DARK);
  const textColor = useColorModeValue(COLOR_DARK_ACCENT, 'white');
  const textHoverColor = useColorModeValue('white', COLOR_DARK_ACCENT);
  const backgroundColor = useColorModeValue('white', COLOR_DARK_ACCENT);
  const backgroundHoverColor = useColorModeValue(COLOR_DARK_ACCENT, 'white');
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ThankYouEmailContent>();

  const onSubmit: SubmitHandler<ThankYouEmailContent> = useCallback(
    async (data: ThankYouEmailContent) => {
      const content = {
        ...data,
        subject: intl.formatMessage({ id: 'email.thankYou.subject' }),
        shortText: intl.formatMessage(
          { id: 'email.thankYou.shortText' },
          { companyName: OUR_COMPANY_NAME, responseHours: OUR_RESPONSE_TIME_IN_HOURS, phone: OUR_CONTACT_PHONE },
        ),
        headerGreeting: intl.formatMessage({ id: 'email.thankYou.headerGreeting' }, { name: data.name }),
        welcomeMessage: intl.formatMessage({ id: 'email.thankYou.welcomeMessage' }, { companyName: OUR_COMPANY_NAME }),
        ourResponseMessage: intl.formatMessage(
          { id: 'email.thankYou.ourResponseMessage' },
          { responseHours: OUR_RESPONSE_TIME_IN_HOURS },
        ),
        contactUsMessage: intl.formatMessage({ id: 'email.thankYou.contactUsMessage' }, { phone: OUR_CONTACT_PHONE }),
        seeYouMessage: intl.formatMessage({ id: 'email.thankYou.seeYouMessage' }),
        byeBye: intl.formatMessage({ id: 'email.thankYou.byeBye' }),
      };

      await axios
        .post('/.netlify/functions/sendEmail', content)
        .then(() =>
          toast({
            title: intl.formatMessage({ id: `${intlPath}.success` }),
            position: 'top',
            status: 'success',
            isClosable: true,
            size: 'sm',
          }),
        )
        .catch(() => {
          toast({
            title: intl.formatMessage({ id: `${intlPath}.error` }),
            position: 'top',
            status: 'error',
            isClosable: true,
            size: 'sm',
          });
        });
    },
    [intl, toast],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-netlify="true">
      <FormControl isInvalid={!isUndefined(errors.name)}>
        <Box id={ContactFormContentPropNamesEnum.Name} pt={4}>
          <FormErrorMessage>
            {errors.name && intl.formatMessage({ id: `${intlPath}.${ContactFormContentPropNamesEnum.Name}.required` })}
          </FormErrorMessage>
          <FormLabel>
            {intl.formatMessage({ id: `${intlPath}.${ContactFormContentPropNamesEnum.Name}.label` })}
          </FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <InfoIcon color="gray.300" />
            </InputLeftElement>
            <Input
              type="text"
              focusBorderColor="#0644BF"
              isInvalid={!isUndefined(errors.name)}
              errorBorderColor="#F21628"
              placeholder={intl.formatMessage({
                id: `${intlPath}.${ContactFormContentPropNamesEnum.Name}.placeholder`,
              })}
              _placeholder={{ color: placeholderColor }}
              {...register('name', { required: true })}
            />
          </InputGroup>
        </Box>
      </FormControl>
      <FormControl isInvalid={!isUndefined(errors.email)}>
        <Box id={ContactFormContentPropNamesEnum.Email} pt={4}>
          <FormErrorMessage>
            {errors.email &&
              intl.formatMessage({ id: `${intlPath}.${ContactFormContentPropNamesEnum.Email}.required` })}
          </FormErrorMessage>
          <FormLabel>
            {intl.formatMessage({ id: `${intlPath}.${ContactFormContentPropNamesEnum.Email}.label` })}
          </FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <EmailIcon color="gray.300" />
            </InputLeftElement>
            <Input
              type="text"
              focusBorderColor="#0644BF"
              isInvalid={!isUndefined(errors.email)}
              errorBorderColor="#F21628"
              placeholder={intl.formatMessage({
                id: `${intlPath}.${ContactFormContentPropNamesEnum.Email}.placeholder`,
              })}
              _placeholder={{ color: placeholderColor }}
              {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
            />
          </InputGroup>
        </Box>
      </FormControl>
      <FormControl isInvalid={!isUndefined(errors.topic)}>
        <Box id={ContactFormContentPropNamesEnum.Topic} pt={4}>
          <FormErrorMessage>
            {errors.topic &&
              intl.formatMessage({ id: `${intlPath}.${ContactFormContentPropNamesEnum.Topic}.required` })}
          </FormErrorMessage>
          <FormLabel>
            {intl.formatMessage({ id: `${intlPath}.${ContactFormContentPropNamesEnum.Topic}.label` })}
          </FormLabel>
          <InputGroup>
            <Textarea
              size="sm"
              width="100%"
              focusBorderColor="#0644BF"
              isInvalid={!isUndefined(errors.topic)}
              errorBorderColor="#F21628"
              placeholder={intl.formatMessage({
                id: `${intlPath}.${ContactFormContentPropNamesEnum.Topic}.placeholder`,
              })}
              _placeholder={{ color: placeholderColor }}
              {...register(ContactFormContentPropNamesEnum.Topic, { required: true })}
            />
          </InputGroup>
        </Box>
      </FormControl>
      <Box id={ContactFormContentPropNamesEnum.Phone} pt={4}>
        <FormLabel>
          {intl.formatMessage({ id: `${intlPath}.${ContactFormContentPropNamesEnum.Phone}.label` })}
        </FormLabel>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <PhoneIcon color="gray.300" />
          </InputLeftElement>
          <Input
            type="tel"
            focusBorderColor="#0644BF"
            placeholder={intl.formatMessage({ id: `${intlPath}.${ContactFormContentPropNamesEnum.Phone}.placeholder` })}
            _placeholder={{ color: placeholderColor }}
            {...register(ContactFormContentPropNamesEnum.Phone, { required: false })}
          />
        </InputGroup>
      </Box>
      <Box id="btn-submit">
        <HStack align="center" pt={[12, 16]} justifyContent="center">
          <Button
            type="submit"
            variant="outline"
            borderColor={borderColor}
            color={textColor}
            backgroundColor={backgroundColor}
            borderWidth={2}
            borderRadius={25}
            _hover={{
              borderWidth: '0px',
              color: textHoverColor,
              backgroundColor: backgroundHoverColor,
            }}
            width="32"
            isLoading={isSubmitting}
          >
            {intl.formatMessage({ id: 'buttons.send' })}
          </Button>
        </HStack>
      </Box>
      <input
        type="hidden"
        value={intl.locale}
        {...register(ContactFormContentPropNamesEnum.Language, { required: false })}
      />
    </form>
  );
};

export default ContactForm;
