import { Card, CardBody } from '@chakra-ui/card';
import { Box, Heading, Stack, Text, useColorMode } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { OUR_COMPANY_NAME } from '../../constants';

const BusinessCard = () => {
  const { colorMode } = useColorMode();

  return (
    <Box
      borderWidth="2px"
      borderRadius="0px"
      borderColor="gray.200"
      boxShadow="10px 10px 30px #888"
      mt="24"
      mx={[0, 12]}
    >
      <Card direction={{ base: 'column', sm: 'row' }} overflow="hidden" variant="outline">
        <Box px="8" py={[8, 16]}>
          {colorMode === 'dark' ? (
            <StaticImage src="../../images/logo-white.webp" alt={OUR_COMPANY_NAME} placeholder="blurred" width={250} />
          ) : (
            <StaticImage src="../../images/logo-black.webp" alt={OUR_COMPANY_NAME} placeholder="blurred" width={300} />
          )}
        </Box>
        <Box px="8" pt={[4, 16]} pb={[8, 16]}>
          <Stack>
            <CardBody>
              <Heading size="md">Marcin Stanczyk S.L.</Heading>

              <Text>
                Avda. Pintor Felo Monzon 26, P2, 4D
                <br />
                ES-35019 Las Palmas de Gran Canaria
                <br />
                B72662281
                <br />
                <a href="tel:+34624108781">+34 624 108 781</a>
                <br />
                <a href="mailto:info@groupredesign.com">info@groupredesign.com</a>
              </Text>
            </CardBody>
          </Stack>
        </Box>
      </Card>
    </Box>
  );
};

export default BusinessCard;
